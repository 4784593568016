window.$ = window.jQuery = require('jquery');

import Helpers from '../utils/helpers';
window.Helpers = Helpers;

require('../utils/global');

require('bootstrap-sass');

window._tooltip = $.fn.tooltip;
window._button = $.fn.button;

window.$.fn.datetimepicker = require('eonasdan-bootstrap-datetimepicker');
