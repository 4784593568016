(function(global, factory) {

    if (typeof define === "function" && define.amd) {

        define([], factory);
    }
    else if (typeof exports === "object") {

        module.exports = factory();
    }
    else {

        global.Helpers = factory();
    }

})(this, function() {

    var Helpers = function() {};

    // AJAX
    Helpers.prototype.ajaxSetup = function() {

        if (typeof GDS3 !== 'undefined') {

            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': GDS3.csrfToken
                }
            });
        }
    };

    var HelpersObject = new Helpers;

    return HelpersObject;
});
